.Card {
  margin: 40px;
  max-width: 90vw;
  min-width: 90vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 20px;
  border-radius: 5px;
  color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: all 0.8s ease;
}

.Card:active {
  transform: scaleX(0.95) scaleY(1.05);
}

.Card .leftside-container {
  height: 100%;
  padding: 10px;
}

.Card .image-container {
  width: 20vw;
  overflow: hidden;
  border-radius: 5px;
}

.Card .image-container img {
  width: 20vw;
  padding-bottom: 15px;
  border-bottom: 3px solid rgba(0, 0, 0, 0.1);
}

.card-title h3 {
  font-weight: 200;
  font-size: 20px;
  text-align: center;
  background-color: black;
  padding: 10px 25px;
  border-radius: 5px;
}

.Card p {
  font-weight: 200;
  font-size: 20px;
  margin: 20px;
}

.Card .link {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.Card .link a {
  text-decoration: none;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  color: #fff;
  font-size: 20px;
  margin-right: 0 auto;
  padding: 5px 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.Card .link a:hover {
  color: rgba(0, 0, 0, 0.4);
  background-color: #fff;
}

.desc-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.desc-container ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.desc-container ul li {
  font-weight: 300;
  font-size: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 10px 10px;
  border-radius: 5px;
}