.About {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 870px;
  width: 100%;

  transition: all 0.5s ease;
  transform: translateX(100%);
  opacity: 0;
}

.show-about {
  transform: translateX(0);
  opacity: 1;
}

.About .diagonal-line {
  position: absolute;
  top: -100px;
  width: 100%;
  height: 50px;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(150deg, #f4f4f4 40%, #8ec6c5 10%);
}

.About .diagonal-line-bottom {
  position: absolute;
  bottom: -100px;
  width: 100%;
  height: 50px;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(150deg, #8ec6c5 70%, #f4f4f4 10%);
}

.about-container {
  padding: 100px 0;
  width: 640px;
  height: 640px;
  background-color: #8ebfc6;
  color: #fff;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.9s ease;
  animation: jiggle 8s infinite;
}

.about-container h3 {
  font-weight: 200;
  font-size: 50px;
  margin-bottom: 50px;
}

.about-container p {
  font-weight: 300;
  font-size: 35px;
  margin-bottom: 30px;
  text-align: center;
  line-height: 70px;
}

.name {
  font-weight: 300;
  font-size: 35px;
  padding: 5px 8px;
}

.self-taught {
  font-weight: 300;
  font-size: 35px;
  padding: 5px 8px;
}

.many-more {
  font-weight: 500;
  font-size: 35px;
  border-bottom: 1px solid #fff;
  padding: 5px 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.many-more:hover {
  border-bottom: none;
  background-color: #fff;
  border-radius: 4px;
  color: #8ec6c5;
}

.creative {
  font-weight: 300;
  font-size: 35px;
  padding: 5px 8px;
}

.extra-info {
  position: absolute;
  bottom: -700px;
  height: 500px;
  width: 100%;
  background-color: #f4f4f4;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.show-extra-info {
  display: flex;
}

.extra-info h3 {
  font-weight: 300;
  font-size: 30px;
  margin-bottom: 80px;
  color: rgb(175, 175, 175);
}

.extra-info h4 {
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid #8ebfc6;
  padding: 4px 7px;
  color: rgb(175, 175, 175);
}

.extra-info p {
  color: black;
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 40px;
  color: rgb(175, 175, 175);
}

.skill-bubbles {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  background-image: linear-gradient(150deg, #cbeeee, #8ec6c5);
  animation: float 3.8s infinite;
}

.skill-bubbles h3 {
  color: #fff;
  font-weight: 200;
  font-size: 30px;
}

.skill1 {
  top: 0;
  left: 100px;
}

.skill2 {
  top: 400px;
  left: 100px;
  animation-delay: 0.5s;
}

.skill3 {
  top: 0;
  right: 100px;
  animation-delay: 1s;
}

.skill4 {
  top: 400px;
  right: 100px;
  animation-delay: 1.5s;
}

@keyframes float {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(13px);
  }
}