.DescP1 {
  position: absolute;
  top: 0;
  left: 50vw;
  width: 50vw;
  height: calc(100% - 30px);
  background-color: #92c2aa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  margin: 15px 0;

  transition: all 0.5s ease;
  transform: translateX(100%);
  opacity: 0;
}

.DescP1 .exit-btn {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 50px;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
}
