.follow-the-cursor {
  position: absolute;
  top: 10px;
  left: 200px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: rgba(100, 63, 187, 0.3);
  pointer-events: none;
  transition: all 0.2s ease-out;
}

.click-to-follow {
  position: absolute;
  top: 10px;
  left: 200px;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  cursor: pointer;
}

.scroll-el {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
