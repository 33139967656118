.driving-cog1 {
  animation: driving-cog1 10s linear infinite;
  animation-play-state: paused;
}

@keyframes driving-cog1 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
.driving-cog2 {
  animation: driving-cog2 7s linear infinite;
  animation-play-state: paused;
}

@keyframes driving-cog2 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.driving-body {
  animation: driving-body 5s ease-in-out infinite;
  animation-play-state: paused;
}

@keyframes driving-body {
  0%,
  100% {
    transform: rotate(0) translate(0, 0);
  }
  10% {
    transform: rotate(3deg) translate(3px, 3px);
  }

  30% {
    transform: rotate(-2deg) translate(-4px, -2px);
  }

  40% {
    transform: rotate(1deg) translate(1px, 1px);
  }
  80% {
    transform: rotate(-1deg) translate(-1px, -1px);
  }
  90% {
    transform: rotate(5deg) translate(0, -10px);
  }
}

.driving-tire1 {
  animation: driving-tire1 5s ease-in-out infinite;
  animation-play-state: paused;
}

@keyframes driving-tire1 {
  0%,
  100% {
    transform: rotate(0) translate(0, 0);
  }
  14% {
    transform: rotate(3deg) translate(3px, 3px);
  }

  34% {
    transform: rotate(-2deg) translate(-4px, -2px);
  }

  44% {
    transform: rotate(1deg) translate(1px, 1px);
  }
  84% {
    transform: rotate(-1deg) translate(-1px, -1px);
  }
  94% {
    transform: rotate(5deg) translate(0, -10px);
  }
}

.driving-tire2 {
  animation: driving-tire2 5s ease infinite;
  animation-play-state: paused;
}

@keyframes driving-tire2 {
  0%,
  100% {
    transform: rotate(0) translate(0, 0);
  }
  12% {
    transform: rotate(-3deg) translate(-3px, -3px);
  }

  32% {
    transform: rotate(2deg) translate(2px, 2px);
  }

  42% {
    transform: rotate(-1deg) translate(-1px, -1px);
  }
  82% {
    transform: rotate(1deg) translate(1px, 1px);
  }
  92% {
    transform: rotate(-5deg) translate(0, 7px);
  }
}
.driving-img {
  animation: driving-img 5s ease-in-out infinite;
  animation-play-state: paused;
}

@keyframes driving-img {
  0%,
  100% {
    transform: rotate(0) translate(0, 0);
  }
  14% {
    transform: rotate(3deg) translate(3px, 3px);
  }

  34% {
    transform: rotate(-2deg) translate(-4px, -2px);
  }

  44% {
    transform: rotate(1deg) translate(1px, 1px);
  }
  84% {
    transform: rotate(-1deg) translate(-1px, -1px);
  }
  94% {
    transform: rotate(5deg) translate(0, -10px);
  }
}

.play {
  animation-play-state: running;
}
