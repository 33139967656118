nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 350px;
  transform: translateX(-100%) scaleX(-1);
  transition: all 0.8s ease;
  z-index: 10;
  background-color: #f4f4f4;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  box-shadow: 0 0 5px rgba(142, 198, 197, 0.2);
}
.show-nav {
  transform: translateX(0%) scale(1);
}

nav ul {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  margin: 0 7rem 0 2rem;
}

nav ul li {
  position: relative;
  color: rgb(160, 166, 173);
  list-style: none;
  margin: 2rem;
  font-size: 2rem;
  font-weight: 200;
  cursor: pointer;
  transition: all 0.2s;
  width: max-content;
}

.nav-arrow {
  opacity: 0;
  transition: all 0.4s;
  transform: scale3d(-1, 1, 1);
  color: rgba(142, 198, 197, 0.3);
}

.underline {
  position: absolute;
  height: 13px;
  width: 40%;
  opacity: 0;
  left: 70%;
  transition: opacity 1.2s, left 0.2s, width 0.5s;
  border-radius: 5px;
  margin-top: 0rem;
  z-index: -1;
}

.underline-about {
  background-image: linear-gradient(150deg, #8566aa, #8ec6c5);
}

.underline-project {
  background-image: linear-gradient(150deg, #85acb3, #92c2aa);
}

.underline-contact {
  background-image: linear-gradient(150deg, #7ead8a, #bbc9a2);
}

nav ul li:hover .nav-arrow {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

nav ul li:hover .underline {
  opacity: 0.2;
  left: 32px;
  width: 88%;
}

.selected-page-underline {
  opacity: 0.32;
  left: 32px;
  width: 88%;
}
