/* Circles */
.Circle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  margin: 50px auto;
  width: 640px;
  height: 640px;
  border-radius: 50%;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.9s ease;
  animation: jiggle 8s infinite;

  overflow: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  transition: all 0.9s ease;
  scrollbar-width: none;
}

.Circle::-webkit-scrollbar {
  display: none;
}

.about-page {
  scroll-snap-align: center;
  color: #f4f4f4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(150deg, #8566aa, #8ec6c5);
  background-repeat: no-repeat;
  border-radius: 50%;
  min-height: 640px;
  max-height: 640px;
  min-width: 640px;
  max-width: 640px;
  transition: all 0.9s ease;
}

.project-page {
  position: relative;
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 640px;
  max-height: 640px;
  min-width: 640px;
  max-width: 640px;
  color: #f4f4f4;
  background-image: linear-gradient(150deg, #85acb3, #92c2aa);
  border-radius: 50%;
  transition: all 0.9s;
}

.contact-page {
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 640px;
  max-height: 640px;
  min-width: 640px;
  max-width: 640px;
  color: #f4f4f4;
  background-image: linear-gradient(150deg, #7ead8a, #bbc9a2);
  transition: all 0.9s;
  border-radius: 50%;
}

/* About page */
.about-page h1 {
  font-weight: 100;
  font-size: 3rem;
  margin-bottom: 4rem;
}
.about-page h2 {
  font-weight: 100;
  font-size: 2rem;
}

.k {
  font-size: 5rem;
  font-weight: 100;
  display: inline-block;
  animation: jiggle 8s ease-in-out infinite;
  animation-delay: 0.3s;
}

.n {
  font-size: 4.5rem;
  padding-left: 1.5rem;
  font-weight: 100;
  display: inline-block;
  animation: jiggle 8s ease-in-out infinite;
  animation-delay: 0.5s;
}

.name-animation {
  display: inline-block;
  opacity: 0;
  transition: all 0.5s ease;
  transform: translateY(40px);
}

.fade-in {
  opacity: 1;
  transform: translateY(0);
  color: #fff;
}

.developer {
  opacity: 0;
  transform: scaleY(-0.2);
  transition: all 1s ease-out;
}

.show-developer {
  opacity: 1;
  transform: scaleY(1);
}

/* Projects */
.project-page h2 {
  font-weight: 100;
  font-size: 50px;
}

/* Contact */
.contact-page h2 {
  font-weight: 100;
  font-size: 50px;
}

/* Animation */
@keyframes jiggle {
  20%,
  100% {
    transform: rotate(0deg);
  }
  7% {
    transform: rotate(2deg);
  }
  9% {
    transform: rotate(-2deg);
  }
  12% {
    transform: rotate(1.4deg);
  }
  15% {
    transform: rotate(-1.4deg);
  }
}
