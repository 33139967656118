.show-Desc {
  transform: translateX(0);
  opacity: 1;
}

.Desc h2 {
  font-weight: 300;
  font-size: 30px;
  border-bottom: 2px solid #fff;
  padding: 5px 50px;
  margin-bottom: 20px;
}

.Desc p {
  font-weight: 300;
  font-size: 23px;
  margin-bottom: 40px;
  text-align: center;
}

.Desc ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.Desc ul li {
  font-weight: 300;
  font-size: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin: 10px;
  padding: 12px 7px;
  border-radius: 5px;
}

.Desc .link {
  margin-top: 40px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.Desc .link a {
  text-decoration: none;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  color: #fff;
  font-size: 20px;
  margin-right: 0 auto;
  padding: 5px 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.Desc .link a:hover {
  color: #92c2aa;
  background-color: #fff;
}