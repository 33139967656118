.Contact {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 870px;
  width: 100%;

  transition: all 0.5s ease;
  transform: translateX(100%);
  opacity: 0;
}

.show-contact {
  transform: translateX(0);
  opacity: 1;
}

.Contact .diagonal-line {
  position: absolute;
  top: -100px;
  width: 100%;
  height: 50px;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(150deg, #f4f4f4 40%, #b5c9a2 10%);
}
.Contact .diagonal-line-bottom {
  position: absolute;
  bottom: -100px;
  width: 100%;
  height: 50px;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(150deg, #b5c9a2 70%, #f4f4f4 10%);
}

.contact-container {
  padding: 100px 0;
  width: 640px;
  height: 640px;
  border-radius: 50%;
  background-color: #adc9a2;
  color: #fff;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.9s ease;
  animation: jiggle 8s infinite;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-container h4 {
  font-weight: 300;
  font-size: 30px;
  margin-bottom: 20px;
  border-bottom: 1px solid #fff;
  padding: 5px 10px;
}

.contact-container p {
  font-weight: 200;
  font-size: 30px;
  margin-bottom: 40px;
}
