.Car1,
.Car2 {
  position: relative;
  width: 320px;
  height: 300px;
  margin: 150px 120px 50px 80px;
  cursor: pointer;

  transition: all 0.5s ease;
  opacity: 1;
  transform: translateX(0);
}

.scale-container {
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
}

.hide-car1 {
  opacity: 0;
  transform: translateX(-100%);
}
.hide-car2 {
  opacity: 0;
  transform: translateX(100%);
}

.Car1 .img-container,
.Car2 .img-container {
  border-radius: 10px;
  position: absolute;
  top: 0px;
  left: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  width: 230px;
  height: 200px;
}

.cog1 {
  position: absolute;
  font-size: 140px;
  color: white;
  top: -50px;
  right: -30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 5px;
}

.cog2 {
  position: absolute;
  font-size: 90px;
  color: white;
  bottom: 60px;
  left: -40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 5px;
}

.car-body {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 180px;
  background-color: #e296b6;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.car-tire1 {
  position: absolute;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  bottom: -50px;
  left: 10px;

  background-color: #e0b132;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.car-tire2 {
  position: absolute;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  bottom: -50px;
  right: 10px;

  background-color: #e25252;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.title-container {
  position: absolute;
  bottom: 35px;
  width: 100%;
  text-align: center;
  background-color: black;
  padding: 10px 0;
}
.title-container h3 {
  font-weight: 300;
}

.Car2 .car-body {
  background-color: #e0b132;
}

.Car2 .car-tire1 {
  background-color: #e25252;
}

.Car2 .car-tire2 {
  background-color: #e296b6;
}
