/* bubble 1 */

.container-bubble1 {
  position: absolute;
  top: 100px;
  right: 500px;
  opacity: 0.5;
  z-index: -1;
  transform: rotate(-30deg);
  transition: all 1.7s ease-out;
}
.bubble1 {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  scrollbar-width: none;
  scroll-behavior: smooth;
}
.bubble1::-webkit-scrollbar {
  display: none;
}

.about-bubble1 {
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(150deg, #8566aa, #8ec6c5);
}
.project-bubble1 {
  position: absolute;
  top: 300px;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(150deg, #85acb3, #92c2aa);
}
.contact-bubble1 {
  position: absolute;
  top: 600px;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(150deg, #7ead8a, #bbc9a2);
}

/* bubble 2 */

.container-bubble2 {
  position: absolute;
  top: 400px;
  right: 120px;
  opacity: 0.5;
  z-index: -1;
  transform: rotate(80deg);
  transition: all 1.2s ease-out;
}
.bubble2 {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  scrollbar-width: none;
  scroll-behavior: smooth;
}
.bubble2::-webkit-scrollbar {
  display: none;
}

.about-bubble2 {
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(150deg, #8566aa, #8ec6c5);
}
.project-bubble2 {
  position: absolute;
  top: 200px;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(150deg, #85acb3, #92c2aa);
}
.contact-bubble2 {
  position: absolute;
  top: 400px;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(150deg, #7ead8a, #bbc9a2);
}

/* bubble 3 */

.container-bubble3 {
  position: absolute;
  top: 400px;
  left: 400px;
  opacity: 0.5;
  z-index: -1;
  transform: rotate(160deg);
  transition: all 1.4s ease-out;
}
.bubble3 {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.bubble3::-webkit-scrollbar {
  display: none;
}

.about-bubble3 {
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(150deg, #8566aa, #8ec6c5);
}
.project-bubble3 {
  position: absolute;
  top: 150px;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(150deg, #85acb3, #92c2aa);
}
.contact-bubble3 {
  position: absolute;
  top: 300px;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(150deg, #7ead8a, #bbc9a2);
}

/* bubble 4 */

/* bubble 5 */
.container-bubble5 {
  position: absolute;
  top: 600px;
  left: 100px;
  opacity: 0.5;
  z-index: -1;
  transform: rotate(30deg);
  transition: all 2s ease-out;
}

.bubble5 {
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.bubble5::-webkit-scrollbar {
  display: none;
}

.about-bubble5 {
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(150deg, #8566aa, #8ec6c5);
}
.project-bubble5 {
  position: absolute;
  top: 150px;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(150deg, #85acb3, #92c2aa);
}
.contact-bubble5 {
  position: absolute;
  top: 300px;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(150deg, #7ead8a, #bbc9a2);
}

.hide-bubble {
  top: 50%;
  left: 50%;
  right: 50%;
  opacity: 0;
}

.add-wobble {
  animation: wobble 1.4s;
}

.about-page-color {
  background-image: linear-gradient(150deg, #8566aa, #8ec6c5);
}
.project-page-color {
  background-image: linear-gradient(150deg, #85acb3, #92c2aa);
}
.contact-page-color {
  background-image: linear-gradient(150deg, #7ead8a, #bbc9a2);
}

@keyframes wobble {
  0% {
    transform: scale3d(1, 1, 1);
  }
  35% {
    transform: scale3d(1.12, 0.88, 1);
  }
  45% {
    transform: scale3d(0.88, 1, 1);
  }
  60% {
    transform: scale3d(1.1, 0.9, 1);
  }
  75% {
    transform: scale3d(0.97, 1.06, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
