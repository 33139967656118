.MenuBtn {
  position: absolute;
  top: 30px;
  left: 130px;
  opacity: 0.9;
  z-index: 11;
  transition: left 1.2s ease-out, top 0.7s linear, opacity 0.4s ease;
  cursor: pointer;
}
.MenuBtn:hover {
  opacity: 0.8;
}
.MenuBtn:hover .toggle-icon {
  transform: rotate(140deg);
}

.move-toggle-bubble {
  left: 280px;
}

.menu-btn-bubble {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  scrollbar-width: none;
  scroll-behavior: smooth;
}
.menu-btn-bubble::-webkit-scrollbar {
  display: none;
}

.toggle-icon {
  position: absolute;
  font-size: 50px;
  top: 22px;
  left: 11px;
  color: #fff;
  transition: all 0.7s ease;
  text-align: center;
  height: 55px;
  width: 80px;
}

.menu-btn-about {
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(150deg, #8566aa, #8ec6c5);
}
.menu-btn-project {
  position: absolute;
  top: 100px;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(150deg, #85acb3, #92c2aa);
}
.menu-btn-contact {
  position: absolute;
  top: 200px;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(150deg, #7ead8a, #bbc9a2);
}
