.Projects {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 870px;
  width: 100%;

  transition: all 0.5s ease;
  transform: translateX(100%);
  opacity: 0;
}
.show-projects {
  transform: translateX(0);
  opacity: 1;
}

.Projects .diagonal-line {
  position: absolute;
  top: -100px;
  width: 100%;
  height: 50px;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(150deg, #f4f4f4 40%, #92c2aa 10%);
}
.Projects .diagonal-line-bottom {
  position: absolute;
  bottom: -100px;
  width: 100%;
  height: 50px;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(150deg, #92c2aa 70%, #f4f4f4 10%);
}

.pickup-projects {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  background-color: black;
  color: #fff;
  padding: 20px 0;
  margin: 15px 0;

  transition: all 0.5s ease;
  transform: translateX(0);
  opacity: 1;
}
.pickup-projects h2 {
  font-weight: 300;
}

.more-projects {
  width: 100%;
  text-align: center;
  background-color: black;
  color: #fff;
  padding: 20px 0;
  margin: 70px 0;

  transition: all 0.5s ease;
  transform: translateX(0);
  opacity: 1;
}
.more-projects h2 {
  font-weight: 300;
}

.hide-pickup-projects-p1 {
  transform: translateX(100%);
  opacity: 0;
}
.hide-pickup-projects-p2 {
  transform: translateX(-100%);
  opacity: 0;
}

.featured-projects-container {
  padding: 100px 0;
  width: 100%;
  background-color: #92c2aa;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.other-projects {
  position: absolute;
  top: 920px;
  width: 100%;
  background-color: #f4f4f4;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  padding-bottom: 100px;
  display: none;
}

.show-other-projects {
  display: flex;
}
