@media (max-width: 1120px) {
  /* Menu button */
  .MenuBtn {
    left: 50px;
  }
  .move-toggle-bubble {
    left: 280px;
  }

  /* About */
  .about-container h3 {
    font-size: 44px;
    margin-bottom: 40px;
  }

  .about-container p {
    font-size: 32px;
    margin-bottom: 27px;
  }

  .name,
  .self-taught,
  .many-more,
  .creative {
    font-size: 32px;
    padding: 4px 7px;
  }

  /* Cars */
  .scale-container {
    left: -13px;
    transform: scale(0.9);
  }

  /* Pickup Projects' Description (Desc) */
  .Desc h2 {
    font-size: 27px;
    border-bottom: 1px solid #fff;
    padding: 5px 30px;
  }

  .Desc p {
    font-size: 20px;
    margin-bottom: 30px;
  }
  .Desc ul li {
    font-size: 17px;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
    margin: 8px;
    padding: 10px 7px;
    border-radius: 5px;
  }

  .Desc .link a {
    font-size: 17px;
    margin-right: 0 auto;
    padding: 5px 20px;
  }

  /* Cards */
  .Card ul li {
    font-size: 17px;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
    margin: 8px;
    padding: 10px 7px;
    border-radius: 5px;
  }
}

@media (max-width: 900px) {
  /* Cars */
  .scale-container {
    left: -26px;
    transform: scale(0.8);
  }

  /* Pickup Projects' Description (Desc) */
  .DescP1 {
    left: 0;
    width: 100vw;
  }
  .DescP2 {
    width: 100vw;
  }

  .DescP1,
  .DescP2 {
    background-color: rgba(146, 194, 170, 0.9);
  }
}

@media (max-width: 820px) {
  /* Cars */
  .scale-container {
    left: -39px;
    transform: scale(0.7);
  }

  /* Pickup Projects' Description (Desc) */
  .Desc .link a {
    text-align: center;
    width: 37vw;
    padding: 15px 20px;
    margin: 0 1vw;
    color: rgba(0, 0, 0, 0.4);
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 2px;
    border-bottom: none;
    border-right: none;
    box-shadow: 0;
  }

  /* Cards */
  .Card {
    flex-direction: column;
    max-width: 90vw !important;
    min-width: 90vw !important;
  }
  .Card .leftside-container {
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Card .image-container {
    width: 40vw;
    overflow: hidden;
    border-radius: 5px;
  }
  .Card .image-container img {
    width: 40vw;
  }

  .card-title h3 {
    width: 90vw;
    border-radius: 0;
  }

  .Card p {
    color: black;
    text-align: center;
  }

  .Card .link a {
    text-align: center;
    width: 32vw;
    padding: 15px 20px;
    margin: 0 1vw;
    color: rgba(0, 0, 0, 0.4);
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 2px;
    border-bottom: none;
    border-right: none;
    box-shadow: 0;
  }

  .Card .desc-container {
    display: flex !important;
  }
  .Card .desc-container ul {
    color: black;
  }
}

@media (max-width: 740px) {
  /* Follow cursor */
  .follow-the-cursor,
  .click-to-follow {
    display: none;
  }

  /* Circle */
  .Circle {
    width: 100vw;
    border-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin: 0;
  }
  .about-page {
    min-width: 100vw;
    max-width: 100vw;
    border-radius: 0;
  }
  .project-page {
    min-width: 100vw;
    max-width: 100vw;
    border-radius: 0;
  }
  .contact-page {
    min-width: 100vw;
    max-width: 100vw;
    border-radius: 0;
  }

  .about-container {
    width: 100%;
    border-radius: 0;
  }

  .skill1 {
    top: -100px;
    left: 10px;
  }
  .skill2 {
    top: 500px;
    left: 10px;
  }
  .skill3 {
    top: -100px;
    right: 10px;
  }
  .skill4 {
    top: 500px;
    right: 10px;
  }

  /* Nav */
  nav {
    width: 100vw;
  }

  nav ul {
    margin: 0;
    margin-top: 40px;
    margin-bottom: -40px;
    width: 100%;
  }

  /* Nav button */
  .MenuBtn {
    left: 30px;
  }

  .move-toggle-bubble {
    left: 70vw;
  }

  .menu-btn-bubble {
    width: 80px;
    height: 80px;
  }

  .toggle-icon {
    font-size: 47px;
    top: 15px;
    left: 10px;
    height: 52px;
    width: 60px;
  }

  /* Bubbles */
  .Bubbles {
    display: none;
  }

  /* Projects */
  .featured-projects-container {
    flex-direction: column;
    padding-top: 110px;
    padding-bottom: 50px;
  }

  /* Cars */
  .Car1,
  .Car2 {
    margin: 5px auto;
  }
  .Car1 {
    margin-bottom: 55px;
  }
  .scale-container {
    left: -2px;
    transform: scale(0.85);
  }

  .contact-container {
    width: 100%;
    border-radius: 0;
  }
}

@media (max-width: 600px) {
  /* About */
  .about-container h3 {
    font-size: 40px;
  }

  .about-container p {
    font-size: 28px;
    margin: 0 10px;
  }

  .name,
  .self-taught,
  .many-more,
  .creative {
    font-size: 28px;
    padding: 3px 5px;
  }
  .skill-bubbles {
    width: 200px;
    height: 200px;
  }
  .skill-bubbles h3 {
    font-size: 27px;
  }
  .skill1 {
    top: -100px;
    left: 10px;
  }
  .skill2 {
    top: 420px;
    left: 10px;
  }
  .skill3 {
    top: 0px;
    right: 10px;
  }
  .skill4 {
    top: 520px;
    right: 10px;
  }
}

@media (max-width: 400px) {
  /* Circle (about) */
  .about-page h1 {
    font-size: 2.2rem;
  }
  .about-page h2 {
    font-size: 1.7rem;
  }

  .k {
    font-size: 4rem;
  }

  .n {
    font-size: 4rem;
    padding-left: 1.5rem;
  }

  /* About */
  .about-container h3 {
    font-size: 35px;
  }
}

@media (max-width: 350px) {
  /* About */
  .about-container h3 {
    font-size: 33px;
    margin-bottom: 20px;
  }

  .about-container p {
    font-size: 25px;
  }

  .name,
  .self-taught,
  .many-more,
  .creative {
    font-size: 25px;
    padding: 0 2px;
  }
}
